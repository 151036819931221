import React, { Fragment } from "react";
import { Divider, Badge, Tag, Button, Col, Row, Form, Input, DatePicker, Popover, Typography } from 'antd';
import moment from 'moment';
import autoBind from 'react-autobind';
//
import Utils from '@/components/Utils';
import config from "@/config/config";
//
export default class CommonUserProfileForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.additionalInputs = this.props.app.themeManager.theme.registration;
    this.state = { data: {} };
  }
  //Life cycle
  componentWillReceiveProps(nextProps) {
    const data = nextProps.data;
    const partsValues = Object.values(this.additionalInputs).map((input) => {
      const partID = input.partitionID.replace(/\./g, '_');
      return {
        key: `parts.${partID}.${input.id}`,
        value: Utils.getNestedObject(data, `parts.${partID}.${input.id}`),
        type: input.type,
      };
    }).reduce((acc, curr) => {
      acc[curr.key] = curr.type == 'date' ? (curr.value ? moment(curr.value) : null) : curr.value;
      return acc;
    }, {});
    //Set state
    this.setState(prevState => ({ ...prevState, data: { ...prevState.data, ...data } }));
    this.form.setFieldsValue({
      firstName: data.firstName, lastName: data.lastName,
      email: data.email, phoneNumber: data.phoneNumber,
      ...partsValues,
    });
  }

  //UI
  render() {
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
        {this._renderBasicInformation()}
        {this._renderDynamicInformation()}
        {this._renderAdditionalInformation()}
      </Form>
    );
  }

  //Form actions
  handleChangeDate(fieldName) {
    return (date, dateString) => {
      Utils.defaultFormChangeHandler({ target: { id: fieldName, value: dateString } }, this);
    };
  }
  handleChange(event) { Utils.defaultFormChangeHandler(event, this); }
  //Utils
  validateFields() { return this.form.validateFields(); }
  getData() { return this.state?.data; }

  /* private render */
  _renderBasicInformation() {
    const editMode = this.props.editMode;
    return (
      <>
        <Row type="flex" justify="start" align="middle">
          <Col span={8}>
            <Form.Item name="firstName" label="First Name" rules={[
              { required: true, message: 'Please, type your first name!' },
              { min: 2, max: 255, message: 'First name must be between 2 and 255 characters' },
            ]}>
              <Input disabled={!editMode} onChange={this.handleChange} />
            </Form.Item>
          </Col>
          <Col span={8} offset={1}>
            <Form.Item name="lastName" label="Last Name" rules={[
              { required: true, message: 'Please, type your last name!' },
              { min: 2, max: 255, message: 'Last name must be between 2 and 255 characters' },
            ]}>
              <Input disabled={!editMode} onChange={this.handleChange} />
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={8}>
            {this._wrapIntoEmailPopover(
              <Form.Item name="email" label="Email" rules={[
                { required: true, message: 'Please, type your email!' },
                { min: 2, max: 255, message: 'Email name must be between 2 and 255 characters' },
                { type: 'email', message: 'Invalid email format' }
              ]}>
                <Input disabled={!editMode} onChange={this.handleChange}
                  suffix={(this.state.data?.id ? (this.state.data?.confirmationDate > 0 ? <Tag color='green'>Confirmed</Tag> : <Tag color='red'>Unconfirmed</Tag>) : <></>)} />
              </Form.Item>
            )}
          </Col>
        </Row>
      </>
    );
  }

  _renderAdditionalInformation() {
    const editMode = this.props.editMode;
    return (
      <>
        <Divider orientation="left">Other</Divider>
        <Row type="flex" justify="start" align="middle" style={{ marginTop: '20px' }}>
          <Col span={8}>
            <Typography.Text strong style={{ marginRight: '5px' }}>Multi-Factor Authentication:</Typography.Text>
            <Button disabled={!editMode} type="link" onClick={this.props.onMFAToggle}>
              <Tag> {this.state.data?.mfaEnabled ? 'Enabled' : 'Disabled'} <Badge status={this.state.data?.mfaEnabled ? 'success' : 'error'} /> </Tag>
            </Button>
          </Col>
          {this.state.data?.mfaEnabled && this.props.app.themeManager.theme.branding.hidePhoneNumberField == false &&
            <Col span={8} offset={1}>
              {this._wrapIntoPhonePopover(<Form.Item name="phoneNumber" label="Phone Number" rules={[
                { required: false },
                { pattern: /^[\d\-\(\)+ ]+$/, message: 'Invalid phone number! Only numbers, spaces, hyphens and parentheses' },
              ]}>
                <Input disabled={!editMode || this.state.data?.mfaEnabled} onChange={this.handleChange}
                  suffix={(this.state.data?.phoneNumber ? (this.state.data?.phoneConfirmationDate > 0 ? <Tag color='green'>Confirmed</Tag> : <Tag color='red'>Unconfirmed</Tag>) : <></>)} />
              </Form.Item>)}
            </Col>
          }
        </Row>
        {(!(!this.state.data.federation || this.state.data.federation?.length <= 0)) && <Row type="flex" justify="start" align="middle" style={{ marginTop: '20px', marginBottom: '20px' }}>
          <Typography.Text strong style={{ marginRight: '15px' }}>This account is linked with the following provider(s): </Typography.Text> {this.state.data.federation?.map((f, i) => <Tag key={i}>{f}</Tag>)}
        </Row>}
      </>
    );
  }

  /* Dynamic partitions */
  _renderDynamicInformation() {
    const editMode = this.props.editMode;
    const additionalInputs = this.additionalInputs;
    if (!additionalInputs || Object.keys(additionalInputs).length <= 0) return (<></>);
    return (
      <>
        <Divider orientation="left">Personal Information</Divider>
        <Row type="flex" justify="start" align="middle">
          {Object.values(additionalInputs).map((input, index) => {
            const partID = input.partitionID.replace(/\./g, '_');
            return (
              <Fragment key={input + index}>
                <Col span={7} key={index}>
                  {this._renderDynamicFormItem(input, partID, editMode)}
                </Col>
                <Col span={1} />
              </Fragment>
            );
          })}
        </Row>
      </>
    );
  }
  _renderDynamicFormItem(input, partID, editMode) {
    const rules = [];
    if (input.required) rules.push({ required: true, message: 'This field is required!' });
    if (input?.customProps?.maxLength) rules.push({ max: input.customProps.maxLength, message: `Must be lower than ${input.customProps.maxLength}` });
    if (input?.customProps?.pattern) {
      const type = typeof input.customProps.pattern;
      const pattern = type == 'object' ? input.customProps.pattern.regex : input.customProps.pattern;
      const message = type == 'object' ? input.customProps.pattern.message : 'Invalid format';
      rules.push({ pattern, message });
    }
    return (
      <Fragment key={input}>
        <Form.Item name={`parts.${partID}.${input.id}`} label={input.label} rules={rules}>
          {input.type === 'date' ?
            (<DatePicker onChange={this.handleChangeDate(`parts.${partID}.${input.id}`)} style={{ width: '100%' }} />) :
            (<Input id={`parts.${partID}.${input.id}`} type={input.type} disabled={!editMode} placeholder={input.placeholder || ''} onChange={this.handleChange} />)
          }
        </Form.Item>
      </Fragment>
    );
  }

  /* popovers */
  _wrapIntoPhonePopover(toWrap) {
    if (!this.state.data?.mfaEnabled) return (<>{toWrap}</>);
    else return (<Popover trigger="focus" className='phoneDisabledTooltip' getPopupContainer={triggerNode => triggerNode.parentNode}
      content={
        <div style={{ marginLeft: '16px', padding: '0px 10px 0px 10px', width: '250px' }}>
          <Typography.Title level={5}>If you want to change tour phone number, please, disable the MFA and chage it.</Typography.Title>
        </div>
      }
    >{toWrap}</Popover>);
  }
  _wrapIntoEmailPopover(toWrap) {
    if (this.props.isNew || this.props.app.isAdmin()) return (<>{toWrap}</>);
    else return (<Popover trigger="focus" className='emailChangeTooltip' getPopupContainer={triggerNode => triggerNode.parentNode}
      content={
        <div style={{ marginLeft: '16px', padding: '0px 10px 0px 10px', width: '400px' }}>
          <Typography.Title level={4}>Important notes for email change:</Typography.Title>
          <ul>
            <li>In order to take effect you <u>will be logged out</u> after changing the email.</li>
            <li>If wrong email is typed, email change can lock you out of your account.</li>
            <li>You will be asked to confirm the new email by entering a code sent on the new email.</li>
          </ul>
        </div>
      }
    >{toWrap}</Popover>);
  }
}
