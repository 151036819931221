module.exports = {
  "IDMClientOptions": {
    "externalAuth": false,
    "cookiesEndpoint": "https://dakeryn-apps.com/a",
    "apiEndpoint": "https://api-auth-dev.dakeryn-apps.com",
    "roles": {
      "USER": "59a72bbd-9a43-427e-9c92-afd74ab19431",
      "ADMIN": "MY-APP-ADMIN-ROLE",
      "ROOT": "59a72bbd-9a43-427e-9c92-afd74ab19433"
    },
    "partitions": {
      "PERSONAL": "ca.bccsa.personal",
      "PROFESSIONAL": "ca.bccsa.professional"
    }
  },
  "IncludesStageOnName": false,
  "Stage": "dev",
  "ApplicationRecaptchaKey": "6LcDDb0UAAAAAD23S6b-nClSa0DV3XP08IK0k4_H",
  "ApplicationAdvancedSearchDisabled": false,
  "ApplicationRoutes": {
    "login": "/",
    "homepage": "/",
    "register": "/register",
    "registrationConfirmation": "/registrationConfirmation/:id/:id2",
    "registrationConfirmationMail": "/registrationConfirmation/:id",
    "registrationConfirmationReset": "/registrationConfirmation/reset/:id",
    "resetPassword": "/resetPass/:id",
    "resetPasswordRaw": "/resetPass/",
    "resetPasswordConfirmation": "/resetPass/confirm/:id",
    "setupPassword": "/setupPass/:id/:id2",
    "user": "/user/:id",
    "profile": "/user",
    "users": "/users",
    "applications": "/apps",
    "application": "/app/:id",
    "role": "/app/:id/role/:id2",
    "accessToken": "/app/:id/accessToken/:id2",
    "partitionACLs": "/acls",
    "partitionACL": "/acl/:id",
    "authError": "/authError",
    "smsMFA": "/smfa",
    "contactAdmin": "mailto:support@ikonintegration.com"
  },
  "ThemeDomainsMapping": {
    "*.dakeryn-apps.com": {
      "themeKey": "orangepiggy",
      "adminRole": "762aeb14-393_Docket",
      "userRole": "85436518-6c4_Docket",
      "idmKey": "23918f05-b822-5151-9460-6fad595c0902",
      "idmSecret": "ad6042e7-7ac4-59f9-990e-f33877d886a6",
      "idmExternalAuthDomain": "auth-dev.dakeryn-apps.com"
    }
  },
  "DatadogOptions": {
    "applicationId": "a8039f15-8b00-4cae-86af-e5f95cd24ece",
    "clientToken": "pub1e0e6e2f2186d5b5c5d0b66627a2481f",
    "site": "datadoghq.com",
    "service": "idm-fe",
    "env": "development",
    "sampleRate": 100,
    "trackInteractions": true,
    "defaultPrivacyLevel": "allow"
  }
}